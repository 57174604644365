// extracted by mini-css-extract-plugin
export var actions = "product-section-module--actions--d6e7a";
export var boldLabel = "product-section-module--bold-label--bc472";
export var container = "product-section-module--container--e01ee";
export var description = "product-section-module--description--f2cea";
export var downloadButton = "product-section-module--downloadButton--88418";
export var grid = "product-section-module--grid--88947";
export var image = "product-section-module--image--ce54e";
export var label = "product-section-module--label--0d410";
export var reverse = "product-section-module--reverse--5ee2b";
export var section = "product-section-module--section--dd5f1";
export var techBlock = "product-section-module--tech-block--30342";
export var technologies = "product-section-module--technologies--15f14";
export var text = "product-section-module--text--4dbc6";
export var title = "product-section-module--title--fcedd";